/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
// import navbarimg from "../../assets/images/top-removebg.png";
import navbarimg from "../../assets/images/top-nav.png";
const Navigation = ({ isOpen, currentRoute }) => {
  const navigate = useNavigate();

  const LogOut = () => {
    sessionStorage.removeItem("token", { path: "/" });
    sessionStorage.removeItem("agentDetails", { path: "/" });
    sessionStorage.removeItem("userDetails", { path: "/" });
    sessionStorage.removeItem("agentData", { path: "/" });
    sessionStorage.removeItem("userdata", { path: "/" });
    localStorage.removeItem("agentData");
    localStorage.removeItem("userdata");
    navigate("/");
  };
  return (
    <header className="header">
      <div className="header__logo">
        <img src={navbarimg} alt="Logo" />
      </div>
      <nav className="header__nav">
        <ul>
          <li>About US <span className="splash"> | </span></li><li>MGM <span className="splash"> | </span></li><li>Tutorials <span className="splash"> | </span></li><li>FAQ <span className="splash"> | </span></li>
          <li>Downloads <span className="splash"> | </span></li><li>Contact Us</li>
        </ul>
      </nav>
      <div className="header__actions" style={{ position: "relative" }}>
        <ul>
          <li onClick={() => { navigate("/dashboard"); window.location.reload()}}> Manage My Points <span className="splash">|</span></li>
          <li onClick={() => navigate("/child-registration")}> Child Registration  <span className="splash">|</span></li> 
          <li onClick={() => navigate("/change-password")}>Change Password <span className="splash">|</span></li> 
          <li onClick={() => navigate("/change-pin")}>PIN <span className="splash">|</span></li>
          <li onClick={() => navigate("/update-profile")}>Update Profile <span className="splash">|</span></li>
          <li onClick={() => navigate("/drew-details")}><span className="fw_bold">Draw Details</span> <span className="splash">|</span></li>
          <li onClick={() => navigate("#")}>Download Patch <span className="splash">|</span></li>
          <li onClick={() => navigate("#")}>Play Games <span className="splash">|</span></li>
          <li onClick={() => navigate("/pin-password")}>Pin & Password <span className="splash">|</span></li>
          <li onClick={LogOut}>Logout</li>
        </ul>
      </div>
    </header>
  );
};

export default Navigation;
