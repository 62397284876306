import React, { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

function Layout({ children, title }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const MenuToggle = () => {
    setShow(!show);
  };

  const LogOut = () => {
    sessionStorage.removeItem("token", { path: "/" });
    sessionStorage.removeItem("agentDetails", { path: "/" });
    sessionStorage.removeItem("userDetails", { path: "/" });
    sessionStorage.removeItem("agentData", { path: "/" });
    sessionStorage.removeItem("userdata", { path: "/" });
    localStorage.removeItem("agentData");
    localStorage.removeItem("userdata");
    navigate("/");
  };
  return (
    <div className="gridContainer dashboard_play">
      <Header />
      <Suspense fallback={""}>
        <div className={"container"}>{children}</div>
      </Suspense>
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
